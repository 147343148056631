// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  vinApi: "https://vpic.nhtsa.dot.gov/api",
  vinAudit: "https://api.vinaudit.com/v2/query",
  carsXEApi: "http://api.carsxe.com/",
  stripe: "pk_test_RoJfvTfcJau77bwCxyaw9eFe00BhyQaUzo",
  mapbox:
    "pk.eyJ1IjoiZnVtZXM2MjQiLCJhIjoiY2tqdXkyOWF5MjlyZDJyazM5eGxicTJmZSJ9.EsuSdcl0KGcjFcIRPWJyLA",
  logging: true,
  owner: "https://fleetr-4a9e7.firebaseapp.com",
  driver: "https://driver-f7e90.firebaseapp.com",
  landing: "https://landing-d3f25.firebaseapp.com",
  firebase: {
    "projectId": "fleetr-4a9e7",
    "appId": "1:837415164337:web:fbe02d67947b30f0c24590",
    "databaseURL": "https://fleetr-4a9e7.firebaseio.com",
    "storageBucket": "fleetr-4a9e7.appspot.com",
    "locationId": "us-east1",
    "apiKey": "AIzaSyATLQ5RLVdRCKRcnAJmsKC33jXWM566Xps",
    "authDomain": "fumes-ssr--fleetr-4a9e7.us-central1.hosted.app",
    "messagingSenderId": "837415164337",
    "measurementId": "G-MDR1T81PPJ"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
